<template>
    <div class="appendedFile">
        <div class="validFile" v-if="fileObject.url">
            <img 
                :src="fileObject.url" 
                :alt="fileObject.name"
                @error="handleImageError"
            >
            <span class="uploadSuccess">
                <i class="el-icon-upload-success el-icon-check"></i>
            </span>
            <div class="optionsLayer">
                <button type="button" :disabled="showProgressBar">
                    <i class="el-icon-zoom-in" @click="previewHandle"></i>
                </button>
                <button type="button" :disabled="showProgressBar">
                    <i class="el-icon-delete" @click="removeHandling"></i>
                </button>
            </div>
        </div>
        <div class="validFile" v-else>
            <el-progress type="circle" :percentage="progress" v-if="showProgressBar"/>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    data() {
        return {
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
            progress:0,
            showProgressBar:false,

            imageLoadError:false,

        }
    },
    props:{
        setFile:{
            type: Function
        },
        removeAsset:{
            type: Function
        },
        removeSingleFile:{
            type: Function
        },
        fileObject:{
            description: 'File object'
        },
        moduleType:{
            type:String,
            required: true,
            description: 'module type',
            default: 'products'
        },
        // productId:{
        //     description: 'selected product id'
        // },
        // indexOfFile:{
        //     description:'index of file'
        // },
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
        }),
    },
    methods:{
        async fileUploadHandle(){

            if(this.fileObject.type == 'image/jpeg' || this.fileObject.type == 'image/jpg' || 
            this.fileObject.type == 'image/webp' || this.fileObject.type == 'image/png'){
                
                if(this.moduleType == 'products'){

                    let formData = new FormData();

                    formData.append('file',this.fileObject);

                    try{
                        const response = await this.$axios.post('/product/add/image-upload', formData, {
                            headers: {'Content-Type': 'multipart/form-data',},
                            onUploadProgress: progressEvent => {
                                this.showProgressBar = true
                                this.progress= parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                            }
                        });
                        if(response.data.status_code == '1009'){

                            // this.$notify({
                            //     title: 'success',
                            //     type: 'Success',
                            //     message: response.data.message,
                            // });

                            this.showProgressBar = false;
                            this.progress = 0;

                            let updatedFileObject = {
                                url: this.base_image_url + this.settings.store_id + '/product/' + response.data.image,
                                name: response.data.image,
                                orgName: this.fileObject.name
                            }

                            this.setUpdatedFile(updatedFileObject);

                            this.$store.commit('settings_module/update_remaining_space',response.data.remaining_space.usage_space);

                        }
                    }catch(error){

                        this.removeSingleFile(this.fileObject)

                        if(error.response){

                            if(error.response.data.error.includes("storage limit has been reached") || 
                            error.response.data.error.includes('deleted or is no longer available')){

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.error,
                                });

                            }else{

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }
                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });
                            
                        }

                    }

                }else if(this.moduleType == 'product-bundles'){

                    let formData = new FormData();

                    formData.append('file',this.fileObject);
                    formData.append('form_type','add');

                    try{
                        const response = await this.$axios.post('/product-bundle/image/upload', formData, {
                            headers: {'Content-Type': 'multipart/form-data',},
                            onUploadProgress: progressEvent => {
                                this.showProgressBar = true
                                this.progress= parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                            }
                        });
                        if(response.data.status_code == '1765'){

                            // this.$notify({
                            //     title: 'success',
                            //     type: 'Success',
                            //     message: response.data.message,
                            // });

                            this.showProgressBar = false;
                            this.progress = 0;

                            let updatedFileObject = {
                                url: this.base_image_url + this.settings.store_id + '/product-bundle/' + response.data.image,
                                name: response.data.image,
                                orgName: this.fileObject.name
                            }

                            this.setUpdatedFile(updatedFileObject);

                            this.$store.commit('settings_module/update_remaining_space',response.data.remaining_space.usage_space);

                        }
                    }catch(error){

                        this.removeSingleFile(this.fileObject)

                        if(error.response){

                            if(error.response.data.error.includes("storage limit has been reached") || 
                            error.response.data.error.includes('deleted or is no longer available')){

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.error,
                                });

                            }else{

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }
                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });
                            
                        }

                    }

                }

            }else{

                if(this.fileObject.type.includes('image/')){

                    let uploadedFileType = this.fileObject.type.slice(6);
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                }

            }
        },
        setUpdatedFile(updateFile){

            this.setFile(updateFile);

        },
        async removeHandling(){

            this.removeAsset(this.fileObject);

        },
        previewHandle(){

            if(this.imageLoadError){

                this.$message({
                    type: "error",
                    showClose: true,
                    message: "The image loading process encountered an error.",
                });

            }else{
                    
                this.$emit('preview-event',this.fileObject);

            }

        },
        
        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png';

            this.imageLoadError = true;

        },
    },
    mounted(){

        if(this.fileObject instanceof File){

            this.fileUploadHandle();
            
        }

    }
}
</script>

<style>

</style>